import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import axios from 'axios'
import Web3Modal from 'web3modal'
import { Row, Col } from 'react-bootstrap'

import AssetCard from './AssetCard'

import { nftmarketaddress, nftaddress } from '../config'

import NFT from '../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../artifacts/contracts/NFTMarket.sol/NFTMarket.json'

const MyAssets = () => {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')

  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()

    const marketContract = new ethers.Contract(
      nftmarketaddress,
      Market.abi,
      signer
    )
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const data = await marketContract.fetchMyNFTs()

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId)
        const meta = await axios.get(tokenUri)
        let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.data.image,
        }
        return item
      })
    )
    setNfts(items)
    setLoadingState('loaded')
  }
  if (loadingState === 'loaded' && !nfts.length)
    return <h1 className='px-20 py-10 text-3xl'>No Assets Owned</h1>
  return (
    <div>
      {/* {nfts.map((nft, i) => (
        <div key={i}>
          <img alt='MED' src={nft.image} className='rounded' />
          <div className='p-4 bg-black'>
            <p className='text-2xl font-bold text-white'>
              Price - {nft.price} Eth
            </p>
          </div>
        </div>
      ))} */}
      <Row>
        {nfts.map((nft, index) => (
          <Col key={index} sm={12} md={6} lg={4} xl={3}>
            <AssetCard nft={nft} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default MyAssets
