import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import Rating from '../components/Rating'

import { ethers } from 'ethers'
import axios from 'axios'
import Web3Modal from 'web3modal'
import { Row, Col } from 'react-bootstrap'

import { nftaddress, nftmarketaddress } from '../config'

import NFT from '../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../artifacts/contracts/NFTMarket.sol/NFTMarket.json'

const NFTCard = ({ nft }) => {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')

  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {
    const provider = new ethers.providers.JsonRpcProvider()
    // const provider = window.web3.currentProvider
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const marketContract = new ethers.Contract(
      nftmarketaddress,
      Market.abi,
      provider
    )
    // Fetch all the unsold market Item from the Smart Contract
    const data = await marketContract.fetchMarketItems()

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId)
        const meta = await axios.get(tokenUri)
        let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
        let item = {
          price,
          itemId: i.itemId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
        }
        return item
      })
    )
    setNfts(items)
    setLoadingState('loaded')
  }

  async function buyNft(nft) {
    // web3Modal looks for an Ethereum instance if it is injected in the web browser or not
    // In order to connect our metamask wallet!
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)

    const signer = provider.getSigner()
    const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)

    const price = ethers.utils.parseUnits(nft.price.toString(), 'ether')
    const transaction = await contract.createMarketSale(
      nftaddress,
      nft.itemId,
      {
        value: price,
      }
    )
    // wait until the transaction executed!
    await transaction.wait()
    loadNFTs()
  }

  return (
    // <Card className='my-3 p-3 rounded'>
    //   <Link to={`/product/${nft._id}`}>
    //     <Card.Img src={nft.image} variant='top' />
    //   </Link>
    //   <Card.Body>
    //     <Link to={`/product/${nft._id}`}>
    //       <Card.Title as='div'>
    //         <strong>{nft.name}</strong>
    //       </Card.Title>
    //     </Link>

    //     <Card.Text as='div'>
    //       {/* <div className='my-3'>
    //         {product.rating} from {product.numReviews} reviews
    //       </div> */}
    //       {nft.description}
    //     </Card.Text>

    //     <Card.Text as='h3'>{nft.price} ETH</Card.Text>
    //   </Card.Body>
    // </Card>
   
    <Card className='my-3 p-3 rounded nftC'>
      <Card.Img variant='top' src={nft.image} />
      <Card.Body>
        <Link to={`/nfts`}>
        <Card.Title as='div'><strong>{nft.name}</strong></Card.Title>
        
        </Link>
        <Card.Text as='div'>{nft.description}</Card.Text>
        <Card.Text as='h3'>{nft.price} ETH</Card.Text>
        <Button variant='primary' onClick={() => buyNft(nft)}>
          Buy
        </Button>
      </Card.Body>
    </Card>



    
  )
}

export default NFTCard
