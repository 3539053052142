import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import Rating from '../components/Rating'

import { ethers } from 'ethers'
import axios from 'axios'
import Web3Modal from 'web3modal'
import { Row, Col } from 'react-bootstrap'

import { nftaddress, nftmarketaddress } from '../config'

import NFT from '../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../artifacts/contracts/NFTMarket.sol/NFTMarket.json'

const AssetCard = ({ nft }) => {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')

  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {
    const provider = new ethers.providers.JsonRpcProvider()
    // const provider = window.web3.currentProvider
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const marketContract = new ethers.Contract(
      nftmarketaddress,
      Market.abi,
      provider
    )
    // Fetch all the unsold market Item from the Smart Contract
    const data = await marketContract.fetchMarketItems()

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId)
        const meta = await axios.get(tokenUri)
        let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
        let item = {
          price,
          itemId: i.itemId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
        }
        return item
      })
    )
    setNfts(items)
    setLoadingState('loaded')
  }

  return (
    <Card className='my-3 p-3 rounded'>
      <Card.Img variant='top' src={nft.image} />
      <Card.Body>
      <Link to={`/my-assets`}>
        <Card.Title as='div'><strong>{nft.name}</strong></Card.Title>
        </Link>
        <Card.Text as='div'>{nft.description}</Card.Text>
        <Card.Text as='h3'>{nft.price} ETH</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default AssetCard
