// export const nftmarketaddress = "0x9ccB561DCCc7FE81876719e1553a3B42Bb1255D7"
// export const nftaddress = "0x179d0F66636dc961FAA2ec67Dc0283756A076300"

// export const nftmarketaddress = '0x306649dDAB1b5812cd2C1673d61781619586afFB'
// export const nftaddress = '0x24EE9C7e99bbcacA3590dE62b76f7E7742738877'

// // Private network; localhost
// export const nftmarketaddress = '0x5FbDB2315678afecb367f032d93F642f64180aa3'
// export const nftaddress = '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512'

// Ropsten network
export const nftmarketaddress = '0x7Aeb8701D1d3a514144FAe3cdA5F9E86B198982d'
export const nftaddress = '0x21091af73e7B9b4C2c61E1C3A8760525ECd26B6E'

// Polygon mumbai testnet
// export const nftmarketaddress = '0x1aBEF1782b22c77ddb54930adb55e98F17F7C70f'
// export const nftaddress = '0xC09DF70B17A724672BAA47f0b1Ffc2753c1AfbfC'
