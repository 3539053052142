import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import Nfts from './screens/Nfts'
import CreateItem from './screens/CreateItem'
import MyAssets from './screens/MyAssets'
import CreatorDashboard from './screens/CreatorDashboard'

const App = () => {
  return (
    <Router>
      <>
        <Header />
        <main className='py-3'>
          <Container fluid>
            <Routes>
              <Route path='/' element={<HomeScreen />} exact />
              <Route path='/product/:id' element={<ProductScreen />} exact />
              <Route path='/nfts' element={<Nfts />} exact />
              <Route path='create-item' element={<CreateItem />} />
              <Route path='my-assets' element={<MyAssets />} />
              <Route path='creator-dashboard' element={<CreatorDashboard />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </>
    </Router>
  )
}

export default App
