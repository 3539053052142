import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Web3Modal from 'web3modal'
import {
  Row,
  Col,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
  Form,
} from 'react-bootstrap'

import { nftaddress, nftmarketaddress } from '../config'

import NFT from '../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../artifacts/contracts/NFTMarket.sol/NFTMarket.json'

import NFTCard from './NFTCard'
import Collapsible from 'react-collapsible'
// let rpcEndpoint = null

// if (process.env.NEXT_PUBLIC_WORKSPACE_URL) {
//   rpcEndpoint = process.env.NEXT_PUBLIC_WORKSPACE_URL
// }

const Nfts = () => {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')

  // Search Filter
  const [filter, setFilter] = useState('')

  const searchText = (event) => {
    setFilter(event.target.value)
  }

  let dataSearch = nfts.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    )
  })

  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {
    const provider = new ethers.providers.JsonRpcProvider(
      'https://ropsten.infura.io/v3/5ad111900d8e4066a0d80ffa4a0df88d'
    )
    // const provider = window.web3.currentProvider
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const marketContract = new ethers.Contract(
      nftmarketaddress,
      Market.abi,
      provider
    )
    // Fetch all the unsold market Item from the Smart Contract
    const data = await marketContract.fetchMarketItems()

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId)
        const meta = await axios.get(tokenUri)
        let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
        let item = {
          price,
          itemId: i.itemId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
        }
        return item
      })
    )
    setNfts(items)
    setLoadingState('loaded')
  }

  async function buyNft(nft) {
    // web3Modal looks for an Ethereum instance if it is injected in the web browser or not
    // In order to connect our metamask wallet!
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)

    const signer = provider.getSigner()
    const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)

    const price = ethers.utils.parseUnits(nft.price.toString(), 'ether')
    const transaction = await contract.createMarketSale(
      nftaddress,
      nft.itemId,
      {
        value: price,
      }
    )
    // wait until the transaction executed!
    await transaction.wait()
    loadNFTs()
  }

  console.log('nfts: ', nfts)

  if (loadingState === 'loaded' && !nfts.length)
    return <h1 className='px-20 py-10 text-3xl'>No items in marketplace</h1>
  return (
    <div>
      {
        <Tabs
          defaultActiveKey='cat1'
          id='uncontrolled-tab-example'
          className='mb-3 navPanel'
        >
          <Tab eventKey='cat1' title='Catégorie 1'>
            <Row>
              <Col sm={12} md={3} lg={3} xl={2}>
                <h3>Filtre</h3>

                <FilterC trigger='Class' contenu='...' />
                <FilterC trigger='Parts' contenu='...' />
                <FilterC trigger='Purity & Genes' contenu='....' />
                <FilterC trigger='Breed count' contenu='...' />
                <FilterC trigger='Stats' contenu='....' />
                <FilterC trigger='Other' contenu='....' />
              </Col>

              <Col sm={12} md={9} lg={9} xl={10}>
                {/* <div className='col-12 mb-5'>
                  <div className='mb-3 col-12 mx-auto'>
                    <label className='form-label h4'>Search</label>
                    <input
                      type='text'
                      className='form-control'
                      value={filter}
                      onChange={searchText.bind(this)}
                    />
                  </div>
                </div> */}
                <Form.Control
                  type='text'
                  onChange={searchText.bind(this)}
                  value={filter}
                  placeholder='Search Item...'
                ></Form.Control>

                <br />
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={{ span: 4, offset: 8 }}
                    xl={{ span: 4, offset: 8 }}
                  >
                    <div className='filterItem'>
                      <DropdownButton
                        id='dropdown-item-button'
                        title=' Lowest ID '
                      >
                        <Dropdown.Item as='button'>Price</Dropdown.Item>
                        <Dropdown.Item as='button'>Class</Dropdown.Item>
                      </DropdownButton>
                      <i className='fa-brands fa-microsoft'></i>
                      <i className='fa-solid fa-list'></i>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {dataSearch.map((nft, index) => (
                    <Col key={index} sm={12} md={6} lg={4} xl={3}>
                      <NFTCard nft={nft} />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey='cat2' title='Catégorie 2'>
            test2
          </Tab>
          <Tab eventKey='cat3' title='Catégorie 3'>
            test3
          </Tab>
        </Tabs>

        /* {nfts.map((nft, index) => (
        <div className='card' style={{ width: '25%' }}>
          <img src={nft.image} className='card-img-top' alt='...' />
          <div className='card-body'>
            <h5 className='card-title'>{nft.name}</h5>
            <p className='card-text'>{nft.description}</p>
            <p className='card-text'>{nft.price} ETH</p>

            <a
              href='#!'
              onClick={() => buyNft(nft)}
              className='btn btn-primary'
            >
              Buy
            </a>
          </div>
        </div>
      ))} */
      }
    </div>
  )
}

function FilterC({ trigger, contenu }) {
  return (
    <Collapsible trigger={trigger}>
      <p>{contenu}</p>
    </Collapsible>
  )
}

export default Nfts
