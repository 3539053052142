import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'

import Web3Modal from 'web3modal'

import { nftaddress, nftmarketaddress } from '../config'

import NFT from '../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../artifacts/contracts/NFTMarket.sol/NFTMarket.json'

import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap'

const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const CreateItem = () => {
  const navigate = useNavigate()

  const [fileUrl, setFileUrl] = useState(null)
  const [formInput, updateFormInput] = useState({
    price: '',
    name: '',
    description: '',
  })

  async function onChange(e) {
    const file = e.target.files[0]
    try {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      })
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      setFileUrl(url)
      console.log('url: ', url)
    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }
  async function createItem() {
    const { name, description, price } = formInput
    if (!name || !description || !price || !fileUrl) return
    /* first, upload to IPFS */
    const data = JSON.stringify({
      name,
      description,
      image: fileUrl,
    })
    try {
      const added = await client.add(data)
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      createSale(url)
    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }

  async function createSale(url) {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()

    /* next, create the item */
    let contract = new ethers.Contract(nftaddress, NFT.abi, signer)
    let transaction = await contract.createToken(url)
    let tx = await transaction.wait()
    let event = tx.events[0]
    let value = event.args[2]
    let tokenId = value.toNumber()

    const price = ethers.utils.parseUnits(formInput.price, 'ether')

    /* then list the item for sale on the marketplace */
    contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
    let listingPrice = await contract.getListingPrice()
    listingPrice = listingPrice.toString()

    transaction = await contract.createMarketItem(nftaddress, tokenId, price, {
      value: listingPrice,
    })
    await transaction.wait()
    navigate('/nfts')
  }

  return (
    <div>
      <h3>Create Item</h3>
      <Form>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Asset Name'
            aria-label='Sizing example input'
            aria-describedby='inputGroup-sizing-default'
            onChange={(e) =>
              updateFormInput({ ...formInput, name: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Name of nft</Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
          <Form.Label>Asset Description</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            className='form-control'
            aria-label='With textarea'
            placeholder='Asset Description'
            onChange={(e) =>
              updateFormInput({ ...formInput, description: e.target.value })
            }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Price</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Asset Price in Ether'
            aria-label='Dollar amount (with dot and two decimal places)'
            onChange={(e) =>
              updateFormInput({ ...formInput, price: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Price in Matic</Form.Text>
        </Form.Group>

        <div>
          <input
            type='file'
            name='Asset'
            className='my-4'
            onChange={onChange}
          />
        </div>

        {fileUrl && (
          <img className='rounded mt-4' alt='MED' width='350' src={fileUrl} />
        )}

        {/* <Button variant='primary' type='submit' onClick={createItem}>
          Create Digital Asset
        </Button> */}
        {/* <button onClick={createItem}>Create Digital Asset</button> */}
        <div className='d-grid gap-2'>
          <button
            className='btn btn-lg btn-primary'
            type='button'
            onClick={createItem}
            style={{marginTop:20}}
          >
            Create Digital Asset
          </button>
        </div>
      </Form>
    </div>
  )
}

export default CreateItem
