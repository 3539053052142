import React, { useState, useEffect } from 'react'
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { shortenAddress } from '../utils/shortenAddress'

import { ethers } from 'ethers'
const { ethereum } = window

const networks = {
  polygon: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    blockExplorerUrls: ['https://bscscan.com'],
  },
}

const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!ethereum) throw new Error('No crypto wallet found')
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          ...networks[networkName],
        },
      ],
    })
  } catch (err) {
    setError(err.message)
  }
}

const Header = () => {
  const [currentAccount, setCurrentAccount] = useState('')

  const [error, setError] = useState()

  const connectWallet = async () => {
    try {
      if (!ethereum) return alert('Please install metamask')
      // Get all the accounts and the user will be able to choose one.
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

      setCurrentAccount(accounts[0])
    } catch (error) {
      console.log(error)
      throw new Error('No ethereum object.')
    }
  }

  const checkIfWalletIsConnected = async () => {
    try {
      if (!ethereum) return alert('Please install metamask')

      const accounts = await ethereum.request({ method: 'eth_accounts' })

      if (accounts.length) {
        setCurrentAccount(accounts[0])
      } else {
        console.log('No Accounts found')
      }
    } catch (error) {
      console.log(error)
      throw new Error('No ethereum object.')
    }
  }

  console.log('current account: ', currentAccount)

  // Control current Network via the API exposed by Metamask! (A nice user experience perspective)
  // If for example the use hasn't the BSC Mainnet in his wallet, then it will ask him
  // to add it inside his wallet
  const handleNetworkSwitch = async (networkName) => {
    setError()
    await changeNetwork({ networkName, setError })
  }

  const networkChanged = (chainId) => {
    console.log({ chainId })
  }

  useEffect(() => {
    checkIfWalletIsConnected()

    window.ethereum.on('chainChanged', networkChanged)

    return () => {
      window.ethereum.removeListener('chainChanged', networkChanged)
    }
  }, [])

  // useEffect(() => {
  //   checkIfWalletIsConnected()
  // }, [])

  return (
    <header>
      <Navbar bg='primary' variant='dark' expand='lg' collapseOnSelect>
        <Container
        // fluid
        >
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src='/images/LogoOpen2Be.png' width='135' />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav className='me-auto my-2 my-lg-0' navbarScroll>
              {/* <LinkContainer to='/'>
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>  */}
              <LinkContainer to='/nfts'>
                <Nav.Link>Marketplace</Nav.Link>
              </LinkContainer>
              <LinkContainer className='d-none' to='/create-item'>
                <Nav.Link>Sell Digital Asset</Nav.Link>
              </LinkContainer>
              <LinkContainer className='d-none' to='/my-assets'>
                <Nav.Link>My Digital Assets</Nav.Link>
              </LinkContainer>
              <LinkContainer className='d-none' to='/creator-dashboard'>
                <Nav.Link>Creator Dashboard</Nav.Link>
              </LinkContainer>
              {/* <NavDropdown title='Link' id='navbarScrollingDropdown'>
                <NavDropdown.Item href='#action3'>Action</NavDropdown.Item>
                <NavDropdown.Item href='#action4'>
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='#action5'>
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href='#' disabled>
                Link
              </Nav.Link> */}
            </Nav>
            {/* <Form className='d-flex'>
              <FormControl
                type='search'
                placeholder='Search'
                className='me-2'
                aria-label='Search'
              />
              <Button variant='outline-success'>Search</Button>
            </Form> */}
            {!currentAccount ? (
              <button
                type='button'
                className='btn btn-primary btnConn'
                onClick={connectWallet}
              >
                Connect Wallet
              </button>
            ) : (
              // <button type='button' class='btn btn-primary'>
              //   {shortenAddress(currentAccount)}
              // </button>
              <DropdownButton
                id='dropdown-basic-button'
                title={shortenAddress(currentAccount)}
                className='padd'
              >
                <Dropdown.Item
                  href='#/action-1'
                  onClick={() => handleNetworkSwitch('polygon')}
                >
                  Switch To Polygon
                </Dropdown.Item>
                <Dropdown.Item
                  href='#/action-2'
                  onClick={() => handleNetworkSwitch('bsc')}
                >
                  Switch To BSC
                </Dropdown.Item>
              </DropdownButton>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
